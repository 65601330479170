<span class="input-label" [class.required]="_required">
  {{ _label }}
</span>
<input
  [type]="_type"
  [ngModel]="_value$ | async"
  (ngModelChange)="update.emit($event); _value$.next($event)"
  (focus)="blur.emit($event)"
  (blur)="blur.emit($event)"
  [disabled]="(_disabled$ | async) || false"
  [placeholder]="_placeholder"
  [required]="_required"
  #inputElement
  class="input-field"
/>
