import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import AirDatepicker, { AirDatepickerOptions } from 'air-datepicker';

@Component({
  selector: 'wlx-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements AfterViewInit {
  @ViewChild('inputElement')
  inputElement: ElementRef;

  @Input()
  airPickerOptions?: AirDatepickerOptions;

  @Input()
  set label(value: string) {
    this._label = value;
  }

  @Input()
  set type(value: 'text' | 'email' | 'password' | 'date' | 'datetime' | 'search' | 'tel') {
    this._type = value;
  }

  @Input()
  set placeholder(value: string) {
    this._placeholder = value;
  }

  @Input()
  set value(value: string | null | undefined) {
    if (value === null || value === undefined) {
      return;
    }
    this._value$.next(value);
  }

  @Input()
  set disabled(value: boolean | string | undefined) {
    switch (typeof value) {
      case 'boolean':
        this._disabled$.next(value);
        return;
      case 'string':
        this._disabled$.next(true);
        return;
      default:
        this._disabled$.next(false);
        return;
    }
  }

  @Input()
  set required(value: boolean | string | undefined) {
    switch (typeof value) {
      case 'boolean':
        this._required = value;
        return;
      case 'string':
        this._required = true;
        return;
      default:
        this._required = false;
        return;
    }
  }

  @Output() update = new EventEmitter<string>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter();

  public _label = '';
  public _type: 'text' | 'email' | 'password' | 'date' | 'datetime' | 'search' | 'tel' = 'text';
  public _placeholder = '';
  public _required = false;
  public _value$ = new BehaviorSubject<string | undefined>(undefined);
  public _disabled$ = new BehaviorSubject<boolean>(false);

  public getValue(event: Event) {
    console.log(event);
    console.log((event.target as HTMLInputElement).value);

    return (event.target as HTMLInputElement).value;
  }

  ngAfterViewInit() {
    if (this.airPickerOptions) {
      new AirDatepicker(this.inputElement.nativeElement as HTMLInputElement, {
        ...this.airPickerOptions,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSelect: ({ date, formattedDate, datepicker }) => {
          console.log('sel', formattedDate);
          this._value$.next(formattedDate as string);
          this.update.next(formattedDate as string);
          this.blur.next(formattedDate as string);
        },
      });
      this._type = 'text';
    }
  }
}
