import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wlx-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsComponent implements OnInit {
  @Input() public column: undefined | string;
  @Input() public row: undefined | string;

  @HostBinding('class.column') public _column = false;
  @HostBinding('class.row') public _row = false;

  public ngOnInit(): void {
    this.evaluateDirectionInputs();
  }

  public evaluateDirectionInputs(): void {
    if (this.multipleDirectionsSpecified()) {
      throw new Error('Received more than one direction input, but only one should be specified');
    }

    this.invalidateDirections();

    if (this.shouldRenderVertically()) this._column = true;
    else if (this.shouldRenderHorizontally()) this._row = true;
    else this._column = true;
  }

  public countDirectionInputs(): number {
    return [this.shouldRenderHorizontally(), this.shouldRenderVertically()]
      .map<number>(b => (b ? 1 : 0))
      .reduce((a, b) => a + b);
  }

  public invalidateDirections(): void {
    this._column = this._row = false;
  }

  public multipleDirectionsSpecified(): boolean {
    return this.countDirectionInputs() > 1;
  }

  public shouldRenderHorizontally(): boolean {
    return this.row !== undefined;
  }

  public shouldRenderVertically(): boolean {
    return this.column !== undefined;
  }
}
