import {
  animate,
  AnimationMetadata,
  group,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'wlx-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [allowSelectionChangeTrigger()],
})
export class SelectComponent {
  @Input()
  set label(value: string) {
    this._label = value;
  }

  @Input()
  set value(value: string | null | undefined) {
    if (value === null || value === undefined) {
      return;
    }
    this._value$.next(value);
  }

  @Input()
  set allowSelection(value: boolean | string | undefined) {
    switch (typeof value) {
      case 'boolean':
        this._selectionAllowed$.next(value);
        return;
      case 'string':
        this._selectionAllowed$.next(true);
        return;
      default:
        this._selectionAllowed$.next(false);
        return;
    }
  }

  @Input()
  set disableSelection(value: boolean | string | undefined) {
    switch (typeof value) {
      case 'boolean':
        this._selectionDisabled$.next(value);
        return;
      case 'string':
        this._selectionDisabled$.next(true);
        return;
      default:
        this._selectionDisabled$.next(false);
        return;
    }
  }

  @Output() update = new EventEmitter<string>();

  public _label = '';
  public _value$ = new BehaviorSubject<string | undefined>(undefined);
  public _options = new Array<{ disabled: boolean; value: string }>();
  public _selectionAllowed$ = new BehaviorSubject<boolean>(false);
  public _selectionDisabled$ = new BehaviorSubject<boolean>(false);
}

function allowSelectionChangeTrigger(): AnimationMetadata {
  return trigger('allowSelectionChange', [
    transition(':enter', [
      style({ opacity: 0, transform: 'translateX(50px)' }),
      group([
        animate(
          '500ms 0ms cubic-bezier(0.230, 1.000, 0.320, 1.000)',
          style({ opacity: 1, transform: 'translateX(0px)' })
        ),
      ]),
    ]),
    transition(':leave', [
      style({ opacity: 1, transform: 'translateX(0px)' }),
      animate(
        '500ms 0ms cubic-bezier(0.230, 1.000, 0.320, 1.000)',
        keyframes([style({ opacity: 0, transform: 'translateX(-50px)' })])
      ),
    ]),
  ]);
}
