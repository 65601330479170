<button class="counter-action" (click)="decreaseAmount($event)">
  <wlx-icon [name]="'minus'"></wlx-icon>
</button>
<span class="counter-amount">
  {{ amount }}
  <ng-container *ngIf="renderOutOf">
    /
    {{ max }}
  </ng-container>
</span>
<button class="counter-action" (click)="increaseAmount($event)">
  <wlx-icon [name]="'plus'"></wlx-icon>
</button>
