import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExtraComponent } from './extra.component';
import { CardModule } from '../card/card.module';
import { IconModule } from '../icon/icon.module';
import { CounterModule } from '../counter/counter.module';

@NgModule({
  declarations: [ExtraComponent],
  imports: [CommonModule, CardModule, IconModule, CounterModule],
  exports: [ExtraComponent],
})
export class ExtraModule {}
