import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { IconModule } from '../icon/icon.module';
import { FormsModule } from '@angular/forms';
import { SelectOptionComponent } from './option/option.component';

@NgModule({
  declarations: [SelectComponent, SelectOptionComponent],
  imports: [CommonModule, FormsModule, IconModule],
  exports: [SelectComponent, SelectOptionComponent],
})
export class SelectModule {}
