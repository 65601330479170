<ng-container
  *ngIf="{
    isDisabled: _disabled$ | async,
    usePrimaryLook: (_look$ | async) === 'primary',
    useSecondaryLook: (_look$ | async) === 'secondary',
    useShimmerLook: (_look$ | async) === 'shimmer'
  } as state"
>
  <button
    type="button"
    [disabled]="state.isDisabled"
    [class.primary]="state.usePrimaryLook"
    [class.secondary]="state.useSecondaryLook"
    [class.shimmer]="state.useShimmerLook"
  >
    <ng-content></ng-content>
  </button>
</ng-container>
