<div class="milestone">
  <div
    class="milestone-label"
    [class.active]="state === 'active'"
    [class.completed]="state === 'completed'"
    [class.pending]="state === 'pending'"
  >
    <ng-content></ng-content>
  </div>
</div>
