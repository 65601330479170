import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Icon } from '../../icon/icon';
import { ProgressionComponent } from '../progression.component';
import { MilestoneState } from './milestone';

@Component({
  selector: 'wlx-progression-milestone',
  templateUrl: './milestone.component.html',
  styleUrls: ['./milestone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressionMilestoneComponent {
  @Input()
  @HostBinding('class.is-follow-up')
  set followUp(value: boolean | string | undefined) {
    switch (typeof value) {
      case 'boolean':
        this._isFollowUp = value;
        return;
      case 'string':
        this._isFollowUp = true;
        return;
      default:
        this._isFollowUp = false;
        return;
    }
  }
  get followUp(): boolean {
    return this._isFollowUp;
  }

  @Input()
  state: MilestoneState = 'pending';

  @Input()
  set name(value: string | undefined) {
    if (value === undefined) {
      throw new Error('No milestone name was specified, required for tracking');
    }
    this._name = value;
  }
  get name(): string {
    return this._name;
  }

  _isFollowUp: boolean;
  _name: string;

  constructor(private progression: ProgressionComponent) {
    this._isFollowUp = false;
    this._name = 'undefined';
  }

  public stateBoundIconName(): Icon {
    switch (this.state) {
      case 'active':
        return 'milestone';
      case 'completed':
        return 'check';
      case 'pending':
        return 'circle';
    }
  }
}
