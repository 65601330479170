import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'wlx-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CounterComponent {
  @Input() min = 0;
  @Input() max = Number.MAX_VALUE;
  @Input() amount = 0;
  @Input() renderOutOf = false;

  @Output() amountDecrease = new EventEmitter<CounterAmountChangeEvent>();
  @Output() amountIncrease = new EventEmitter<CounterAmountChangeEvent>();
  @Output() amountChange = new EventEmitter<CounterAmountChangeEvent>();

  constructor(private cdr: ChangeDetectorRef) {}

  public decreaseAmount(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    const previousAmount = this.amount;
    const updatedAmount = this.amount - 1;

    const minimumReached = !(updatedAmount >= this.min);
    if (minimumReached) {
      return;
    }

    this.amount = updatedAmount;
    this.amountDecrease.emit({ previous: previousAmount, current: updatedAmount });
    this.amountChange.emit({ previous: previousAmount, current: updatedAmount });
  }

  public increaseAmount(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    const previousAmount = this.amount;
    const updatedAmount = this.amount + 1;

    const maximumReached = !(updatedAmount <= this.max);
    if (maximumReached) {
      return;
    }

    this.amount = updatedAmount;
    this.amountIncrease.emit({ previous: previousAmount, current: updatedAmount });
    this.amountChange.emit({ previous: previousAmount, current: updatedAmount });
  }
}

export type CounterAmountChangeEvent = { previous: number; current: number };
