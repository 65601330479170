import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SelectComponent } from '../select.component';

@Component({
  selector: 'wlx-select-option',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOptionComponent implements OnInit {
  @Input()
  set value(value: string | undefined) {
    this._value = value;
  }

  @Input()
  set disabled(value: boolean | string | undefined) {
    switch (typeof value) {
      case 'boolean':
        this._disabled = value;
        return;
      case 'string':
        this._disabled = true;
        return;
      default:
        this._disabled = false;
        return;
    }
  }

  _value: string | undefined = undefined;
  _disabled = false;

  constructor(private _selectComponent: SelectComponent) {}

  ngOnInit(): void {
    if (this._value === undefined) {
      throw new Error('Input "value" for "SelectOptionComponent" expected value, received none');
    }
    this._selectComponent._options.push({
      disabled: this._disabled || false,
      value: this._value,
    });
  }
}
