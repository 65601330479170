<label>
  <div class="select-display">
    <span class="select-label">{{ _label }}</span>
    <span class="select-value">{{ _value$ | async }}</span>
  </div>

  <select
    [ngModel]="_value$ | async"
    (ngModelChange)="update.emit($event); _value$.next($event)"
    [disabled]="(_selectionDisabled$ | async) || false"
    class="select-field"
  >
    <option *ngFor="let option of _options" [disabled]="option.disabled" [ngValue]="option.value">
      {{ option.value }}
    </option>
  </select>
  <div *ngIf="_selectionAllowed$ | async" [@allowSelectionChange]="_selectionAllowed$ | async" class="select">
    <wlx-icon x2 class="select-icon" [name]="'chevron-down'"></wlx-icon>
  </div>
</label>
