import {
  animate,
  AnimationMetadata,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CalendarMonthLoadEvent, CalendarDate, CalendarWeek } from './calendar';

@Component({
  selector: 'wlx-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [weekTrigger(), dayTrigger()],
})
export class CalendarComponent implements OnInit {
  @Input()
  set year(value: number) {
    // todo(soupertonic): input checks missing
    this._year = value;
    this._weeks = this.computeWeeksOfMonth(this._year, this._month);
  }

  @Input()
  set month(value: number) {
    // todo(soupertonic): input checks missing
    this._month = value;
    this._weeks = this.computeWeeksOfMonth(this._year, this._month);
  }

  @Input()
  set disabledDates(value: Array<number>) {
    this._disabledDates$.next(value);
  }

  @Input()
  set canNavigateBack(value: boolean) {
    this._canNavigateBack$.next(value);
  }

  @Input()
  set canSelectPast(value: boolean) {
    this._canSelectPast$.next(value);
  }

  @Output() nextMonthLoad = new EventEmitter<CalendarMonthLoadEvent>();
  @Output() previousMonthLoad = new EventEmitter<CalendarMonthLoadEvent>();
  @Output() monthLoad = new EventEmitter<CalendarMonthLoadEvent>();
  @Output() dateSelect = new EventEmitter<CalendarDate>();

  public _year = 0;
  public _month = 0;
  public _weeks = new Array<CalendarWeek>();
  public _selectedDate: CalendarDate | undefined = undefined;
  public _disabledDates$ = new BehaviorSubject<Array<number>>([]);
  public _canNavigateBack$ = new BehaviorSubject<boolean>(true);
  public _canSelectPast$ = new BehaviorSubject<boolean>(true);

  public ngOnInit(): void {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    this.selectMonthAndYear(currentYear, currentMonth);
  }

  public showPreviousMonth() {
    let updatedMonth = this._month - 1;
    let updatedYear = this._year;

    const updatedMonthTriggersYearTurnOver = updatedMonth <= -1;
    if (updatedMonthTriggersYearTurnOver) {
      updatedYear = this._year - 1;
      updatedMonth = 11;
    }

    const cannotGetPastThis = new Date();
    cannotGetPastThis.setMonth(cannotGetPastThis.getMonth() - 1);

    const updatedDate = new Date(updatedYear, updatedMonth);
    const updatedDatePreceedsDateAtInitTime = updatedDate.getTime() < cannotGetPastThis.getTime();
    const backNavigationDisabled = !this._canNavigateBack$.value;

    const cannotNavigateBack = backNavigationDisabled && updatedDatePreceedsDateAtInitTime;
    if (cannotNavigateBack) {
      return;
    }

    this._year = updatedYear;
    this._month = updatedMonth;
    this._weeks = this.computeWeeksOfMonth(this._year, this._month);

    const event: CalendarMonthLoadEvent = {
      updatedMonth: this._month,
      updatedYear: this._year,
    };
    this.monthLoad.emit(event);
    this.nextMonthLoad.emit(event);
  }

  public showNextMonth() {
    const updatedMonthTriggersYearTurnOver = this._month + 1 >= 12;
    if (updatedMonthTriggersYearTurnOver) {
      this._year = this._year + 1;
      this._month = 0;
    } else {
      this._month = this._month + 1;
    }

    this._weeks = this.computeWeeksOfMonth(this._year, this._month);

    const event: CalendarMonthLoadEvent = {
      updatedMonth: this._month,
      updatedYear: this._year,
    };
    this.monthLoad.emit(event);
    this.nextMonthLoad.emit(event);
  }

  public selectDate(date: CalendarDate): void {
    if (date.type === 'blank') {
      return;
    }

    const dateIsDisabled = this._disabledDates$.value.includes(date.origin.getDate());
    if (dateIsDisabled) {
      return;
    }

    this._selectedDate = date;
    this.dateSelect.emit(date);

    if (date.origin.getMonth() != this._month) {
      this.selectMonthAndYear(date.origin.getFullYear(), date.origin.getMonth());
    }
  }

  public selectMonthAndYear(year: number, month: number) {
    this._year = year;
    this._month = month;
    this._weeks = this.computeWeeksOfMonth(this._year, this._month);

    const event: CalendarMonthLoadEvent = {
      updatedMonth: this._month,
      updatedYear: this._year,
    };
    this.monthLoad.emit(event);
  }

  public computeWeeksOfMonth(year: number, month: number): Array<CalendarWeek> {
    const precedingDays = new Array<CalendarDate>();
    const lastDayOfPreviousMonth = new Date(year, month, 0).getDay();
    const lastDateOfPreviousMonth = new Date(year, month, 0).getDate();

    for (let i = lastDayOfPreviousMonth; i > 0; i--) {
      const precedingDay = new Date(year, month - 1, lastDateOfPreviousMonth - i + 1);
      precedingDays.push({
        origin: precedingDay,
        // type: 'preceding',
        type: 'blank',
        disabled: true,
      });
    }

    const actualDays = new Array<CalendarDate>();
    const firstDayOfCurrentMonth = new Date(year, month, 1).getDate();
    const lastDayOfCurrentMonth = new Date(year, month + 1, 0).getDate();

    for (let i = firstDayOfCurrentMonth; i <= lastDayOfCurrentMonth; i++) {
      const day = new Date(year, month, i);
      actualDays.push({
        origin: day,
        type: 'normal',
        disabled: false,
      });
    }

    const totalDaysInRow = 7;
    const totalRowsInMonth = 6;
    // const totalRowsInMonth = 5;

    const daysInTotal = totalDaysInRow * totalRowsInMonth;
    const computedDaysInTotal = precedingDays.length + actualDays.length;

    const remainingDaysInTotal = daysInTotal - computedDaysInTotal;

    const succeedingDays = new Array<CalendarDate>();
    for (let i = 0; i < remainingDaysInTotal; i++) {
      const succeedingDay = new Date(year, month + 1, 1 + i);
      succeedingDays.push({
        origin: succeedingDay,
        // type: 'succeeding',
        type: 'blank',
        disabled: true,
      });
    }

    const days = [...precedingDays, ...actualDays, ...succeedingDays];
    const weeks = new Array<CalendarWeek>();

    // const disableDaysThatArePastToday = !this._canSelectPast$.value;
    // if (disableDaysThatArePastToday) {
    //   const yesterday = new Date();
    //   yesterday.setDate(yesterday.getDate() - 1);

    //   days = days.map((d) => {
    //     const dayIsInPast = d.origin.getTime() < yesterday.getTime();
    //     if (dayIsInPast) {
    //       d.disabled = true;
    //       d.type = 'preceding';
    //       console.log(d);
    //     }
    //     return d;
    //   });
    // }

    for (let i = 0; i < days.length; i += totalDaysInRow) {
      const week = days.slice(i, i + totalDaysInRow);
      weeks.push(week);
    }

    return weeks;
  }
}

function weekTrigger(): AnimationMetadata {
  return trigger('week', [
    transition('* => *', [
      query(
        ':enter',
        [
          style({ opacity: 0, transform: 'translateY(50px)' }),
          stagger(50, [
            animate(
              '750ms cubic-bezier(0.075, 0.820, 0.165, 1.000)',
              style({ opacity: 1, transform: 'translateY(0px)' })
            ),
          ]),
        ],
        {
          optional: true,
        }
      ),
    ]),
  ]);
}

function dayTrigger(): AnimationMetadata {
  return trigger('day', [
    transition('* => *', [
      query(
        ':enter',
        [
          style({ opacity: 0, transform: 'translateY(50px)' }),
          stagger(33, [
            animate(
              '750ms cubic-bezier(0.075, 0.820, 0.165, 1.000)',
              style({ opacity: 1, transform: 'translateY(0px)' })
            ),
          ]),
        ],
        {
          optional: true,
        }
      ),
    ]),
  ]);
}
