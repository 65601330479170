import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from './calendar.component';
import { IconModule } from '../icon/icon.module';
import { MonthNamePipeModule } from '../../pipes/month-name/month-name.pipe';

@NgModule({
  declarations: [CalendarComponent],
  imports: [CommonModule, IconModule, MonthNamePipeModule],
  exports: [CalendarComponent],
})
export class CalendarModule {}
