<div (click)="_ticked = !_ticked; tick.emit(_ticked)" (keypress)="_ticked = !_ticked; tick.emit(_ticked)" tabindex="0">
  <svg
    *ngIf="_ticked"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7778 0H2.22222C0.988889 0 0 1 0 2.22222V17.7778C0 19 0.988889 20 2.22222 20H17.7778C19.0111 20 20 19 20 17.7778V2.22222C20 1 19.0111 0 17.7778 0Z"
      fill="#ddd2c7"
      fill-opacity="0.25098"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.7778 0.833333H2.22222C1.45184 0.833333 0.833333 1.45752 0.833333 2.22222V17.7778C0.833333 18.5425 1.45184 19.1667 2.22222 19.1667H17.7778C18.5482 19.1667 19.1667 18.5425 19.1667 17.7778V2.22222C19.1667 1.45752 18.5482 0.833333 17.7778 0.833333ZM2.22222 0H17.7778C19.0111 0 20 1 20 2.22222V17.7778C20 19 19.0111 20 17.7778 20H2.22222C0.988889 20 0 19 0 17.7778V2.22222C0 1 0.988889 0 2.22222 0Z"
      fill="#ddd2c7"
      fill-opacity="0.5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.659 5.0898L16.2255 6.66736L8.33333 14.5595L3.77383 10L5.34139 8.43244L8.33306 11.4158L14.659 5.0898Z"
      fill="#A57F69"
    />
  </svg>

  <svg
    *ngIf="!_ticked"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7778 0H2.22222C0.988889 0 0 1 0 2.22222V17.7778C0 19 0.988889 20 2.22222 20H17.7778C19.0111 20 20 19 20 17.7778V2.22222C20 1 19.0111 0 17.7778 0Z"
      fill="#BCA090"
      fill-opacity="0.25098"
    />
    <path
      d="M2.22222 0.375H17.7778C18.8028 0.375 19.625 1.20588 19.625 2.22222V17.7778C19.625 18.7941 18.8028 19.625 17.7778 19.625H2.22222C1.19722 19.625 0.375 18.7941 0.375 17.7778V2.22222C0.375 1.20588 1.19722 0.375 2.22222 0.375Z"
      stroke="#BCA090"
      stroke-opacity="0.5"
      stroke-width="0.75"
    />
  </svg>
</div>

<span class="checkbox-prompt">
  <ng-content></ng-content>
</span>
