import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@Pipe({
  name: 'monthName',
})
export class MonthNamePipe implements PipeTransform {
  readonly monthNames = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];

  transform(value: number): string {
    return this.monthNames[value];
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [MonthNamePipe],
  exports: [MonthNamePipe],
})
export class MonthNamePipeModule {}
