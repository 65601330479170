import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipsComponent } from './chips.component';
import { ChipComponent } from './chip/chip.component';

@NgModule({
  declarations: [ChipsComponent, ChipComponent],
  imports: [CommonModule],
  exports: [ChipsComponent, ChipComponent],
})
export class ChipsModule {}
