import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wlx-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent {
  @Input()
  set ticked(value: boolean | string | undefined) {
    switch (typeof value) {
      case 'boolean':
        this._ticked = value;
        return;
      case 'string':
        this._ticked = true;
        return;
      default:
        this._ticked = false;
        return;
    }
  }

  public _ticked = false;

  @Output()
  public tick = new EventEmitter<boolean>();
}
