<ng-container
  *ngIf="{
    year: _year,
    month: _month,
    weeks: _weeks
  } as state"
>
  <div class="month">
    <button class="previous-month" (click)="showPreviousMonth()">
      <wlx-icon [name]="'chevron-left'"></wlx-icon>
    </button>
    <span class="current-month">
      {{ state.month | monthName }}
      {{ state.year }}
    </span>
    <button class="next-month" (click)="showNextMonth()">
      <wlx-icon [name]="'chevron-right'"></wlx-icon>
    </button>
  </div>
  <div class="days">
    <span class="day">Mo</span>
    <span class="day">Di</span>
    <span class="day">Mi</span>
    <span class="day">Do</span>
    <span class="day">Fr</span>
    <span class="day">Sa</span>
    <span class="day">So</span>
  </div>
  <div class="weeks">
    <div *ngFor="let week of state.weeks" class="week">
      <button
        *ngFor="let date of week"
        class="date"
        (click)="selectDate(date)"
        [class.selected]="_selectedDate?.origin?.toString() === date.origin.toString()"
        [class.preceding]="date.type === 'preceding'"
        [class.succeeding]="date.type === 'succeeding'"
        [class.blank]="date.type === 'blank'"
        [class.disabled]="((_disabledDates$ | async) || []).includes(date.origin.getDate())"
      >
        {{ date.origin.getDate() }}
      </button>
    </div>
  </div>
</ng-container>
