import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'wlx-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
  @Input()
  set look(value: ChipComponentLook | undefined) {
    if (value === undefined) {
      return;
    }
    this._look$.next(value);
  }

  @Input()
  set disabled(value: boolean | string | undefined) {
    switch (typeof value) {
      case 'boolean':
        this._disabled$.next(value);
        return;
      case 'string':
        this._disabled$.next(true);
        return;
      default:
        this._disabled$.next(false);
        return;
    }
  }

  @Input()
  set primary(_: string | undefined) {
    this.look = 'primary';
  }

  @Input()
  set secondary(_: string | undefined) {
    this.look = 'secondary';
  }

  public _look$ = new BehaviorSubject<ChipComponentLook>('primary');
  public _disabled$ = new BehaviorSubject<boolean>(false);
}

export type ChipComponentLook = 'primary' | 'secondary' | 'shimmer';
